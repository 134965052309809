import { useQuery } from "react-query";
import { WorthApi } from "../api/WorthApi";

export function useIntegration(id: string) {
  return useQuery([INTEGRATION_KEY, id], () => WorthApi.getIntegration(id), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export const INTEGRATION_KEY = "integration";
