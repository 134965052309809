import { useQuery } from "react-query";
import { WorthApi } from "../api/WorthApi";

export function useIntegrations() {
  return useQuery(INTEGRATIONS_KEY, () => WorthApi.getIntegrations(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export const INTEGRATIONS_KEY = "integrations";
