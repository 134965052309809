export const getIcon = (title: string) => {
  switch (title.toLocaleUpperCase()) {
    case "BANK":
      return "🏦";
    case "CRYPTO":
      return "🪙";
    case "STOCK":
      return "📈";
  }
};
