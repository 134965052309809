import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { WorthApi } from "../api/WorthApi";
import { loggedInAtom } from "../atoms";

export function useAuth() {
  const isLoggedIn = useRecoilValue(loggedInAtom);

  const [state, setState] = useState<
    "unknown" | "notAuthenticated" | "authenticated"
  >("unknown");

  useEffect(() => {
    AsyncStorage.getItem("accessToken").then((res) => {
      if (res === null) {
        setState("notAuthenticated");
        return;
      }
      WorthApi.token = res;
      WorthApi.refreshToken()
        .then((res) => {
          if (res.accessToken) {
            AsyncStorage.setItem("accessToken", res.accessToken);
            setState("authenticated");
          } else {
            setState("notAuthenticated");
          }
        })
        .catch(() => {
          setState("notAuthenticated");
        });
    });
  }, [isLoggedIn]);
  return state;
}
