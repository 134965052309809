import React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useQueryClient } from "react-query";
import { WorthApi } from "../../api/WorthApi";
import { RootStackParamList } from "../../routes";
import { useIntegration } from "../../hooks/useIntegration";
import { INTEGRATIONS_KEY } from "../../hooks/useIntegrations";
import { SUMMARY_KEY } from "../../hooks/useSummary";
import { Content } from "../../components/Content";
import { IntegrationCard } from "./IntegrationCard";

export function IntegrationDetail({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "IntegrationDetail">) {
  const id = route.params.id;

  const { data: integration } = useIntegration(id);
  const queryClient = useQueryClient();

  return (
    <View style={{ flex: 1 }}>
      <Content>
        {integration && (
          <View style={{ marginTop: 12 }}>
            <IntegrationCard integration={integration} />
            <TouchableOpacity
              onPress={() => {
                WorthApi.deleteIntegration(id).then(() => {
                  queryClient.removeQueries({ queryKey: INTEGRATIONS_KEY });
                  queryClient.removeQueries({ queryKey: SUMMARY_KEY });

                  navigation.replace("Integrations");
                });
              }}
              style={[
                styles.button,
                {
                  padding: 4,
                  marginVertical: 6,
                  marginHorizontal: 12,
                  borderRadius: 8,
                  flexDirection: "row",
                },
              ]}
            >
              <Text
                style={[
                  {
                    color: "#ff0000",
                    fontWeight: "500",
                  },
                  styles.buttonText,
                ]}
              >
                Delete Integration
              </Text>
            </TouchableOpacity>
          </View>
        )}
      </Content>
    </View>
  );
}

const styles = StyleSheet.create({
  valueInput: {
    flex: 1,
    marginHorizontal: 12,
    padding: 8,
    borderWidth: 1,
    borderRadius: 6,
  },
  keyInput: {
    marginHorizontal: 12,
    padding: 8,
    borderWidth: 1,

    borderRadius: 6,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "stretch",
  },
  container: { flex: 1, alignItems: "flex-start" },
  button: {
    backgroundColor: "#343245",
    padding: 12,
    marginVertical: 6,
    marginHorizontal: 12,
    borderRadius: 8,
    flexDirection: "row",
  },
  buttonText: {
    margin: 12,
  },
  type: { fontSize: 16, marginBottom: 4, color: "white" },
});
