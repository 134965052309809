import * as React from "react";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  View,
} from "react-native";

import { RootStackParamList } from "../../routes";
import { SummaryCard } from "../../components/SummaryCard";
import { TransactionCard } from "./TransactionCard";
import { useTransactions } from "../../hooks/useTransactions";
import { useBalance } from "../../hooks/useBalance";
import { Content } from "../../components/Content";
import { ScrollView } from "react-native-gesture-handler";
import { Amount } from "../../components/Amount";
import { IntegrationColor } from "../../components/IntegrationColor";

export function BankDetail({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "BankDetail">) {
  const transactions = useTransactions();
  const { data: balance } = useBalance();
  return (
    <View style={styles.container}>
      <Content>
        {!balance && (
          <ActivityIndicator style={{ marginTop: 24 }} color={"white"} />
        )}
        {balance && (
          <FlatList
            ListHeaderComponent={
              <>
                <SummaryCard
                  title={"BANK"}
                  amount={balance?.total.amount || 0}
                  currency={balance?.total.currency || "EUR"}
                />
                <Text
                  style={{
                    color: "white",
                    fontSize: 20,
                    marginLeft: 16,
                    marginTop: 12,
                  }}
                >
                  Accounts
                </Text>
                <ScrollView
                  horizontal
                  contentContainerStyle={{ minHeight: 84, marginBottom: 24 }}
                  style={{ height: 84 }}
                  showsHorizontalScrollIndicator={false}
                >
                  {balance?.balances.map((balanceItem, index) => {
                    const { amount, currency } = balanceItem.balanceAmount;
                    return (
                      <View
                        key={index}
                        style={{
                          width: 150,
                          backgroundColor: "#343245",
                          padding: 12,
                          margin: 12,
                          borderRadius: 8,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text style={{ color: "white" }}>
                            {balanceItem.name || "Account"}
                          </Text>
                          <IntegrationColor
                            integrationId={balanceItem.integration_id}
                          />
                        </View>

                        <Text style={{ color: "white" }}>
                          <Amount
                            amount={parseFloat(amount || "0")}
                            currency={currency}
                          />
                        </Text>
                      </View>
                    );
                  })}
                </ScrollView>
                <Text
                  style={{
                    color: "white",
                    fontSize: 20,
                    marginLeft: 16,
                    marginTop: 12,
                    marginBottom: 8,
                  }}
                >
                  Transactions
                </Text>
              </>
            }
            data={transactions?.data}
            keyExtractor={(item, index) => item.id + "_" + index}
            renderItem={({ item }) => {
              return (
                <TransactionCard
                  title={item.title}
                  price={parseFloat(item.transactionAmount.amount)}
                  currency={item.transactionAmount.currency}
                  date={item.bookingDate}
                  bank={{
                    name: item.integration_name,
                    integration_id: item.integration_id,
                  }}
                  type={item.type}
                />
              );
            }}
          />
        )}
      </Content>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
