import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { useRecoilValue } from "recoil";
import { hideAmounts } from "../../atoms";
import { Amount } from "../../components/Amount";

export const StockCard = ({
  title,
  price,
  currency,
  count,
  changePercentage,
  onPress,
}: {
  title: string;
  price: number;
  currency: string;
  changePercentage: number;
  count: number;
  onPress?: () => void;
}) => {
  const hidden = useRecoilValue(hideAmounts);

  return (
    <TouchableOpacity onPress={onPress} style={styles.button}>
      <View style={styles.left}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.countAndPrice}>
          {hidden ? "***" : count} x {price}
        </Text>
      </View>
      <View style={styles.right}>
        <Text style={styles.value}>
          <Amount amount={price * count} currency={currency} />
        </Text>
        <Text
          style={{
            color:
              changePercentage == 0
                ? "#FFFFFF"
                : changePercentage > 0
                ? "green"
                : "red",
            marginTop: 6,
            fontSize: 11,
            fontWeight: "600",
          }}
        >
          {changePercentage?.toFixed(2)}%
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  value: {
    color: "#FFFFFF",
    fontSize: 14,
    fontWeight: "600",
  },
  right: { alignItems: "flex-end" },
  countAndPrice: {
    color: "#FFFFFF",
    marginTop: 6,
    fontSize: 11,
    fontWeight: "600",
  },
  left: { flex: 1 },
  title: { color: "#FFFFFF", fontWeight: "600" },
  button: {
    backgroundColor: "#343245",
    padding: 12,
    marginVertical: 6,
    marginHorizontal: 12,
    borderRadius: 8,
    flexDirection: "row",
  },
});
