import React from "react";
import {
  Platform,
  Pressable,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { DefaultTheme, NavigationContainer } from "@react-navigation/native";

import { NordigenApprovalWebView } from "./src/WebView";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { linking, RootStackParamList } from "./src/routes";
import { Summary } from "./src/summary";
import { Stock } from "./src/screens/Stock/Stock";
import { StockDetail } from "./src/screens/Stock/StockDetail";
import { RecoilRoot } from "recoil";
import { BankDetail } from "./src/screens/Bank/BankDetail";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./src/queryClient";
import { Crypto } from "./src/screens/Crypto";
import { Add } from "./src/screens/Add/Add";
import { Integrations } from "./src/screens/Integrations";
import { AddManuelCrypto } from "./src/screens/Add/Crypto/AddManuelCrypto";
import { Login } from "./src/screens/Auth/Login";
import { SelectCountry } from "./src/screens/Add/Bank/SelectCountry";
import { SelectInstitution } from "./src/screens/Add/Bank/SelectInstitution";
import { IntegrationDetail } from "./src/screens/Integrations/Integration";
import { useAuth } from "./src/hooks/useAuth";
import { AddBinance } from "./src/screens/Add/Crypto/AddBinance";
import { AddStock } from "./src/screens/Add/Stock/AddStock";
import { SearchStock } from "./src/screens/Add/Stock/SearchStock";
import { AddCryptoWallet } from "./src/screens/Add/Crypto/AddCryptoWallet";

const Stack = createNativeStackNavigator<RootStackParamList>();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <Navigator />
      </RecoilRoot>
    </QueryClientProvider>
  );
}

function Navigator() {
  const auth = useAuth();

  if (auth === "unknown") {
    return <View style={{ backgroundColor: "#201D2B" }} />;
  }

  return (
    <NavigationContainer
      linking={linking}
      theme={{
        dark: true,
        colors: {
          ...DefaultTheme.colors,
          background: "#201D2B",
          text: "#EEEEEE",
          card: "#252525",
        },
      }}
    >
      <Stack.Navigator
        screenOptions={{
          headerShadowVisible: false,
          ...Platform.select({
            web: {
              header: (props) => (
                <View
                  style={{
                    height: 64,
                    flexDirection: "row",
                    alignItems: "center",
                    padding: 12,
                    backgroundColor: "rgb(37, 37, 37)",
                    borderBottomColor: "rgb(216, 216, 216)",
                    borderBottomWidth: 0,
                  }}
                >
                  <Pressable
                    onPress={() => props.navigation.navigate("Summary")}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        color: "white",
                        marginHorizontal: 4,
                        fontWeight: "500",
                      }}
                    >
                      Worth
                    </Text>
                  </Pressable>
                  {props.options.title && (
                    <Text
                      style={{
                        fontSize: 18,
                        color: "white",
                        marginHorizontal: 4,
                        fontWeight: "500",
                      }}
                    >
                      {"> "} {props.options.title}
                    </Text>
                  )}
                  <View style={{ flex: 1 }} />
                  <TouchableOpacity
                    onPress={() => props.navigation.navigate("Integrations")}
                  >
                    <Text style={{ color: "white" }}>Integrations</Text>
                  </TouchableOpacity>
                </View>
              ),
            },
          }),
        }}
        defaultScreenOptions={{ statusBarStyle: "light" }}
      >
        {auth === "notAuthenticated" ? (
          <>
            {/*             <Stack.Screen
              name="Bootstrap"
              component={Bootstrap}
              options={{ header: () => null, statusBarStyle: "light" }}
            /> */}
            <Stack.Screen
              name="Login"
              component={Login}
              options={{ header: () => null, statusBarStyle: "light" }}
            />
          </>
        ) : (
          auth === "authenticated" && (
            <>
              <Stack.Screen name="Summary" component={Summary} />
              <Stack.Screen
                name="Stock"
                options={{ title: "Stock" }}
                component={Stock}
              />
              <Stack.Screen name="StockDetail" component={StockDetail} />
              <Stack.Screen
                options={{ title: "Add Stock" }}
                name="AddStock"
                component={AddStock}
              />
              <Stack.Screen
                options={{ title: "Add Stock" }}
                name="SearchStock"
                component={SearchStock}
              />
              <Stack.Screen
                options={{ title: "Select Country" }}
                name="SelectCountry"
                component={SelectCountry}
              />
              <Stack.Screen
                options={{ title: "Select Institution" }}
                name="SelectInstitution"
                component={SelectInstitution}
              />
              <Stack.Screen
                options={{ title: "Bank" }}
                name="BankDetail"
                component={BankDetail}
              />
              <Stack.Screen
                name="WebView"
                component={NordigenApprovalWebView}
              />
              <Stack.Screen
                options={{ title: "Crypto" }}
                name="Crypto"
                component={Crypto}
              />
              <Stack.Screen
                options={{ title: "Add Wallet" }}
                name="AddManuelCrypto"
                component={AddManuelCrypto}
              />
              <Stack.Screen
                name="AddCryptoWallet"
                component={AddCryptoWallet}
              />
              <Stack.Screen name="Add" component={Add} />
              <Stack.Screen
                options={{ title: "Integrations" }}
                name="Integrations"
                component={Integrations}
              />
              <Stack.Screen
                name="IntegrationDetail"
                component={IntegrationDetail}
              />
              <Stack.Screen
                options={{ title: "Add Binance" }}
                name="AddBinance"
                component={AddBinance}
              />
            </>
          )
        )}
      </Stack.Navigator>
    </NavigationContainer>
  );
}
