import React, { useState } from "react";

import { useTheme } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import { TextInput } from "react-native-gesture-handler";
import { RootStackParamList } from "../../../routes";
import { Content } from "../../../components/Content";
import { WorthApi } from "../../../api/WorthApi";
import { CoinCard } from "../../Crypto/CoinCard";
import { useQueryClient } from "react-query";
import { CRYPTO_SUMMARY_KEY } from "../../../hooks/useCryptoSummary";
import { SUMMARY_KEY } from "../../../hooks/useSummary";

export function AddCryptoWallet({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "AddCryptoWallet">) {
  const { colors } = useTheme();
  const type = route.params.type;
  const [wallet, setWallet] = useState<string>();
  const [walletInfo, setWalletInfo] = useState<any>();
  const queryClient = useQueryClient();

  return (
    <View style={{ flex: 1 }}>
      <Content>
        <Text
          style={{
            color: "white",
            fontSize: 20,
            fontWeight: "500",
            marginVertical: 24,
            marginLeft: 16,
          }}
        >
          Add {type === "AVAX" ? "Avalance" : "Solana"} Wallet
        </Text>
        <Text style={[{ color: colors.text }, styles.countLabel]}>
          Wallet Public Key:
        </Text>
        <TextInput
          keyboardType="default"
          style={[
            {
              color: colors.text,
              borderColor: colors.border,
            },
            styles.input,
          ]}
          value={wallet}
          onChangeText={setWallet}
        />

        {walletInfo && (
          <CoinCard
            title={walletInfo.currency}
            count={walletInfo.balance}
            price={walletInfo.fiat.price}
            currency={walletInfo.fiat.currency}
          />
        )}
        <TouchableOpacity
          onPress={() => {
            if (walletInfo && walletInfo.wallet === wallet) {
              WorthApi.addCryptoWalletSource(
                walletInfo.wallet,
                walletInfo.currency === "AVAX" ? "avax" : "solana"
              ).then(() => {
                queryClient.removeQueries({ queryKey: CRYPTO_SUMMARY_KEY });
                queryClient.removeQueries({ queryKey: SUMMARY_KEY });
                navigation.replace("Crypto");
              });
            }
            if (!wallet) {
              return;
            }
            WorthApi.getWalletBalance(wallet, route.params.type).then((res) => {
              setWalletInfo(res);
            });
          }}
          style={[{}, styles.button]}
        >
          <Text
            style={[
              {
                color: colors.text,
              },
              styles.buttonText,
            ]}
          >
            {walletInfo && walletInfo.wallet === wallet ? "Add" : "Preview"}
          </Text>
        </TouchableOpacity>
      </Content>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginHorizontal: 12,
    padding: 8,
    borderWidth: 1,

    borderRadius: 6,
  },
  buttonText: {
    margin: 12,
  },
  button: {
    backgroundColor: "#7957DA",
    alignItems: "center",
    marginHorizontal: 12,
    marginTop: 12,
    borderRadius: 8,
  },
  countLabel: {
    margin: 12,
  },
  container: {
    flex: 1,
  },
});
