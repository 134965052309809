import { useQuery } from "react-query";
import { WorthApi } from "../api/WorthApi";

export function useSummary() {
  return useQuery(SUMMARY_KEY, () => WorthApi.getSummary(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export const SUMMARY_KEY = "worth-summary";
