import React from "react";
import { StyleSheet, Text, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { IntegrationColor } from "../../components/IntegrationColor";
import { titleCase } from "../../utils/titleCase";

export const IntegrationCard = ({
  integration,
  onPress,
}: {
  integration: { _id: string; type: string; source: string; name: string };
  onPress?: () => void;
}) => {
  return (
    <TouchableOpacity
      key={integration._id}
      onPress={onPress}
      disabled={!onPress}
      style={styles.button}
    >
      <View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 4,
          }}
        >
          <IntegrationColor integrationId={integration._id} />
          <Text style={styles.type}>
            {integration.name || titleCase(integration.type)}
          </Text>
        </View>
        <Text style={{ color: "white" }}>{titleCase(integration.source)}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  type: { marginLeft: 6, fontSize: 16, color: "white" },
  button: {
    backgroundColor: "#343245",
    padding: 12,
    marginVertical: 6,
    marginHorizontal: 12,
    borderRadius: 8,
    flexDirection: "row",
  },
});
