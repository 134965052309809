import AsyncStorage from "@react-native-async-storage/async-storage";
import { atom, selector } from "recoil";
import { AssetSource } from "./types";

export const stockCountsSelector = selector<{ [key: string]: number }>({
  key: "stockCountsSelector",
  get: ({ get }) =>
    (get(assetSourcesAtom).find((s) => s.type === "stock")!.data as any).stocks,
  set: ({ set }, updatedValue) => {
    set(assetSourcesAtom, (old) => {
      const newValue = JSON.parse(JSON.stringify(old)) as AssetSource[];
      const index = newValue.findIndex((s) => s.type === "stock");
      if (index > -1) {
        (newValue[index] as any).data.stocks = updatedValue;
      }
      AsyncStorage.setItem("items", JSON.stringify(newValue));
      return newValue;
    });
  },
});

export const assetSourcesAtom = atom<AssetSource[]>({
  key: "assetSources", // unique ID (with respect to other atoms/selectors)
  default: [],
});

export const loggedInAtom = atom({
  key: "loggedIn", // unique ID (with respect to other atoms/selectors)
  default: false,
});

export const hideAmounts = atom({
  key: "hideAmounts", // unique ID (with respect to other atoms/selectors)
  default: false,
});
