import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { atom, useRecoilState, useRecoilValue } from "recoil";
import { Coin } from "../../api/WorthApi";
import { hideAmounts } from "../../atoms";
import { Amount } from "../../components/Amount";
import { IntegrationColor } from "../../components/IntegrationColor";

export const expandedCoinCard = atom({
  key: "expandedCoinCard", // unique ID (with respect to other atoms/selectors)
  default: "",
});

export const CoinCard = ({
  title,
  price,
  currency,
  count,
  details,
  onPress,
}: {
  title: string;
  price: number;
  currency: string;
  count: number;
  details?: Coin["details"];
  onPress?: () => void;
}) => {
  const hidden = useRecoilValue(hideAmounts);
  const [activeID, setActive] = useRecoilState(expandedCoinCard);
  return (
    <TouchableOpacity
      onPress={() => {
        setActive(activeID !== title ? title : "");
      }}
      style={{
        backgroundColor: "#343245",
        padding: 12,
        marginVertical: 6,
        marginHorizontal: 12,
        borderRadius: 8,
        flexDirection: "row",
      }}
    >
      <View style={{ flex: 1 }}>
        <Text style={{ color: "#FFFFFF", fontWeight: "600" }}>{title}</Text>
        <View
          style={{
            marginTop: 6,
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Text
            style={{
              color: "#FFFFFF",

              fontSize: 11,
              fontWeight: "600",
            }}
          >
            {hidden ? "***" : count.toFixed(8)} x {price}
          </Text>
          {details?.map((a) => (
            <IntegrationColor
              key={a.integration_id}
              integrationId={a.integration_id}
            />
          ))}
        </View>
        {activeID === title && (
          <View style={{ marginTop: 12 }}>
            {details?.map((a) => (
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <IntegrationColor integrationId={a.integration_id} />
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontSize: 14,
                    marginLeft: 6,
                    minWidth: 50,
                  }}
                >
                  {a.name}
                </Text>
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontSize: 14,
                    marginLeft: 36,
                    minWidth: 50,
                  }}
                >
                  {hidden ? "***" : a.amount.toFixed(8)}
                </Text>
                <Text
                  style={{
                    color: "#FFFFFF",
                    fontSize: 14,
                    marginLeft: 36,
                  }}
                >
                  <Amount amount={price * a.amount} currency={currency} />
                </Text>
              </View>
            ))}
          </View>
        )}
      </View>
      <View style={{ alignItems: "flex-end" }}>
        <Text
          style={{
            color: "#FFFFFF",
            fontSize: 14,
            fontWeight: "600",
          }}
        >
          <Amount amount={price * count} currency={currency} />
        </Text>
      </View>
    </TouchableOpacity>
  );
};
