import React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StyleSheet, Text, View } from "react-native";
import { RootStackParamList } from "../../routes";
import { IntegrationCard } from "./IntegrationCard";
import { Content } from "../../components/Content";
import { getIcon } from "../../components/Icon";

export function Add({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "Add">) {
  return (
    <View style={{ flex: 1 }}>
      <Content>
        <Text style={styles.section}>{getIcon("BANK")} BANK</Text>
        <IntegrationCard
          title="Add Bank Account"
          onPress={() => navigation.navigate("SelectCountry")}
        />
        <Text style={styles.section}>{getIcon("STOCK")} STOCK</Text>
        <IntegrationCard
          title="Add Stocks"
          onPress={() => navigation.navigate("SearchStock")}
        />
        <Text style={styles.section}>{getIcon("CRYPTO")} CRYPTO</Text>
        <IntegrationCard
          title="Add Binance Account"
          onPress={() => navigation.navigate("AddBinance")}
        />
        <IntegrationCard
          title="Add Solana Wallet"
          onPress={() =>
            navigation.navigate("AddCryptoWallet", { type: "SOL" })
          }
        />
        <IntegrationCard
          title="Add Avalance Wallet"
          onPress={() =>
            navigation.navigate("AddCryptoWallet", { type: "AVAX" })
          }
        />
        <IntegrationCard
          title="Add Manuel Amount"
          onPress={() => navigation.navigate("AddManuelCrypto")}
        />
      </Content>
    </View>
  );
}

const styles = StyleSheet.create({
  section: { marginLeft: 16, marginVertical: 12, color: "white" },
});
