import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";
import { Amount } from "./Amount";
import { getIcon } from "./Icon";

export const SummaryCard = ({
  title,
  amount,
  currency,
  onPress,
}: {
  title: string;
  amount: number;
  currency: string;
  onPress?: () => void;
}) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.button}>
      <Text style={styles.title}>
        {getIcon(title)} {title}
      </Text>
      <Text style={styles.amount}>
        <Amount amount={amount} currency={currency} />
      </Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  amount: {
    color: "#FFFFFF",
    marginTop: 8,
    fontSize: 24,
    fontWeight: "600",
  },
  title: { color: "#FFFFFF" },
  button: {
    backgroundColor: "#343245",
    padding: 12,
    margin: 12,
    borderRadius: 8,
  },
});
