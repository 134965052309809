import React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Image, Platform, Text, TouchableOpacity, View } from "react-native";
import { RootStackParamList } from "../../routes";
import * as WebBrowser from "expo-web-browser";
import * as Google from "expo-auth-session/providers/google";
import { WorthApi } from "../../api/WorthApi";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useSetRecoilState } from "recoil";
import { loggedInAtom } from "../../atoms";

WebBrowser.maybeCompleteAuthSession();

export function Login({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "Login">) {
  const setLoggedIn = useSetRecoilState(loggedInAtom);

  const [request, response, promptAsync] = Google.useAuthRequest({
    responseType: Platform.OS === "web" ? "id_token" : "code",
    iosClientId:
      "639340097210-3825c8f1n17k9co55ckjb026v35djitu.apps.googleusercontent.com",
    androidClientId:
      "639340097210-v9v3gqslk96sf1ibormsrlfssr7oq42r.apps.googleusercontent.com",
    webClientId:
      "639340097210-3jtm8aa21e8fkbico8ep5nenhgti158n.apps.googleusercontent.com",
    scopes: ["email", "profile"],
  });

  React.useEffect(() => {
    if (response?.type === "success") {
      const token =
        Platform.OS === "web"
          ? response.params.id_token
          : response.authentication?.idToken;

      if (!token) {
        return;
      }
      WorthApi.googleLogin(token).then((res) => {
        console.log(res);
        Promise.all([
          AsyncStorage.setItem("accessToken", res.accessToken),
          AsyncStorage.setItem("email", res.email),
          AsyncStorage.setItem("name", res.name),
          AsyncStorage.setItem("picture", res.picture),
        ]).then(() => {
          setLoggedIn(true);
          //     navigation.replace("");
        });
      });
    }
  }, [response]);

  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <View
        style={{
          width: 96,
          height: 96,
          backgroundColor: "#888888",
          borderRadius: 12,
        }}
      />
      <Text style={{ color: "white", marginTop: 24, fontSize: 24 }}>
        Welcome to Worth!
      </Text>

      <Text
        style={{
          color: "white",
          marginTop: 24,
          marginHorizontal: 16,
          maxWidth: 400,
          textAlign: "center",
        }}
      >
        Track your net worth with ease. Bank accounts, stocks, crypto assets,
        all included.{"\n"}
      </Text>
      <TouchableOpacity
        disabled={!request}
        style={{
          marginTop: 24,
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: "white",
          alignSelf: "center",
          borderRadius: 32,
          height: 44,
          paddingHorizontal: 8,
          overflow: "hidden",
        }}
        onPress={() => {
          promptAsync();
        }}
      >
        <Image
          style={{ width: 32, height: 32 }}
          source={require("../../assets/google.png")}
        />

        <Text
          style={{
            /* color: "white" */
            paddingHorizontal: 12,
          }}
        >
          Sign in with Google
        </Text>
      </TouchableOpacity>
    </View>
    /*  <Button
      disabled={!request}
      title="Google Login"
      onPress={() => {
        promptAsync();
      }}
    /> */
  );
  // return <Text style={{ color: "white" }}>Login</Text>;
}
