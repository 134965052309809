import React from "react";
import { View } from "react-native";

const integrationColors = [
  "#4caf50",
  "#03a9f4",
  "#976ED7",
  "#f44336",
  "#ffeb3b",
  // "#e91e63",
  "#9c27b0",
  "#3f51b5",
  "#2196f3",
  "#00bcd4",
  "#009688",
  "#8bc34a",
  "#ffc107",
  "#cddc39",
  "#ff9800",
  "#ff5722",
];

const integrationColorsMap: { [key: string]: string } = {};

export const IntegrationColor = ({
  integrationId,
}: {
  integrationId: string;
}) => {
  let color = integrationColorsMap[integrationId];
  if (!color) {
    color =
      integrationColors[
        Object.keys(integrationColorsMap).length % integrationColors.length
      ];
    integrationColorsMap[integrationId] = color;
  }
  return (
    <View
      style={{
        marginLeft: 4,
        width: 6,
        height: 6,
        borderRadius: 8,
        backgroundColor: color,
      }}
    />
  );
};
