import { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as React from "react";
import {
  Image,
  ImageSourcePropType,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Content } from "../../../components/Content";
import { RootStackParamList } from "../../../routes";

export function SelectCountry({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "SelectCountry">) {
  return (
    <View style={{ flex: 1 }}>
      <Content>
        <Text
          style={{
            color: "white",
            fontSize: 20,
            fontWeight: "500",
            marginVertical: 24,
            marginLeft: 16,
          }}
        >
          Select a Country
        </Text>
        <ScrollView
          style={{ flex: 1 }}
          contentContainerStyle={{
            flexDirection: "row",
            flexWrap: "wrap",
            paddingBottom: 32,
          }}
        >
          {COUNTRIES.map((c, i) => (
            <TouchableOpacity
              key={i}
              onPress={() => {
                navigation.navigate("SelectInstitution", { country: c.code });
              }}
              style={{
                flexDirection: "row",
                margin: 16,
                width: 200,
                alignItems: "center",
              }}
            >
              <Image
                source={c.flag}
                style={{ width: 50, height: 33 }}
                resizeMethod="auto"
              />
              <Text style={{ color: "white", marginLeft: 8 }}>{c.name}</Text>
            </TouchableOpacity>
          ))}
        </ScrollView>
      </Content>
    </View>
  );
}

type Country = {
  name: string;
  code: string;
  flag: ImageSourcePropType;
};

const COUNTRIES: Country[] = [
  {
    name: "Austria",
    code: "AT",
    flag: require("../../../assets/flags/austria.png"),
  },
  {
    name: "Belgium",
    code: "BE",
    flag: require("../../../assets/flags/belgium.png"),
  },
  {
    name: "Bulgaria",
    code: "BG",
    flag: require("../../../assets/flags/bulgaria.png"),
  },
  {
    name: "Croatia",
    code: "HR",
    flag: require("../../../assets/flags/croatia.png"),
  },
  {
    name: "Cyprus",
    code: "CY",
    flag: require("../../../assets/flags/cyprus.png"),
  },
  {
    name: "Czech Republic",
    code: "CZ",
    flag: require("../../../assets/flags/czechRepublic.png"),
  },
  {
    name: "Denmark",
    code: "DK",
    flag: require("../../../assets/flags/belgium.png"),
  },
  {
    name: "Estonia",
    code: "EE",
    flag: require("../../../assets/flags/estonia.png"),
  },
  {
    name: "Finland",
    code: "FI",
    flag: require("../../../assets/flags/finland.png"),
  },
  {
    name: "France",
    code: "FR",
    flag: require("../../../assets/flags/france.png"),
  },
  {
    name: "Germany",
    code: "DE",
    flag: require("../../../assets/flags/germany.png"),
  },
  {
    name: "Greece",
    code: "GR",
    flag: require("../../../assets/flags/greece.png"),
  },
  {
    name: "Hungary",
    code: "HU",
    flag: require("../../../assets/flags/hungary.png"),
  },
  {
    name: "Iceland",
    code: "IS",
    flag: require("../../../assets/flags/iceland.png"),
  },
  {
    name: "Ireland",
    code: "IE",
    flag: require("../../../assets/flags/ireland.png"),
  },
  {
    name: "Italy",
    code: "IT",
    flag: require("../../../assets/flags/italy.png"),
  },
  {
    name: "Latvia",
    code: "LV",
    flag: require("../../../assets/flags/latvia.png"),
  },
  {
    name: "Liechtenstein",
    code: "LI",
    flag: require("../../../assets/flags/liechtenstein.png"),
  },
  {
    name: "Lithuania",
    code: "LT",
    flag: require("../../../assets/flags/lithuania.png"),
  },
  {
    name: "Luxembourg",
    code: "LU",
    flag: require("../../../assets/flags/luxembourg.png"),
  },
  {
    name: "Malta",
    code: "MT",
    flag: require("../../../assets/flags/malta.png"),
  },
  {
    name: "Netherlands",
    code: "NL",
    flag: require("../../../assets/flags/netherlands.png"),
  },
  {
    name: "Norway",
    code: "NO",
    flag: require("../../../assets/flags/norway.png"),
  },
  {
    name: "Poland",
    code: "PL",
    flag: require("../../../assets/flags/poland.png"),
  },
  {
    name: "Portugal",
    code: "PT",
    flag: require("../../../assets/flags/portugal.png"),
  },
  {
    name: "Romania",
    code: "RO",
    flag: require("../../../assets/flags/romania.png"),
  },
  {
    name: "Slovakia",
    code: "SK",
    flag: require("../../../assets/flags/slovakia.png"),
  },
  {
    name: "Slovenia",
    code: "SI",
    flag: require("../../../assets/flags/slovenia.png"),
  },
  {
    name: "Spain",
    code: "ES",
    flag: require("../../../assets/flags/spain.png"),
  },
  {
    name: "Sweden",
    code: "SE",
    flag: require("../../../assets/flags/sweden.png"),
  },
  {
    name: "United Kingdom",
    code: "GB",
    flag: require("../../../assets/flags/unitedKingdom.png"),
  },
];
