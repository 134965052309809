import * as React from "react";

import { useTheme } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import { RootStackParamList } from "../../routes";
import { useStockPrices } from "../../hooks/useStockPrices";
import { StockCard } from "./StockCard";
import { TextInput } from "react-native-gesture-handler";
import { useSetRecoilState } from "recoil";
import { stockCountsSelector } from "../../atoms";

export function StockDetail({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "StockDetail">) {
  const { colors } = useTheme();
  const stockPrices = useStockPrices();
  const setStockCount = useSetRecoilState(stockCountsSelector);
  const [count, setCount] = React.useState<string | undefined>();
  const s = stockPrices?.stocks?.find((s) => s.symbol === route.params.symbol)!;
  return (
    <View>
      {s && stockPrices?.state === "ready" && (
        <>
          <StockCard
            title={s.shortName}
            price={s.regularMarketPrice.raw}
            count={s.count}
            changePercentage={s.regularMarketChangePercent.raw}
            currency={s.currency}
          />
          <Text style={[{ color: colors.text }, styles.countLabel]}>
            Count:
          </Text>
          <TextInput
            keyboardType="number-pad"
            style={[
              {
                color: colors.text,
                borderColor: colors.border,
              },
              styles.input,
            ]}
            defaultValue={s?.count + ""}
            value={count}
            onChangeText={(text) => setCount(text)}
          />
          <TouchableOpacity
            onPress={() => {
              setStockCount((val) => {
                return { ...val, [s.symbol]: parseInt(count || "") };
              });
            }}
            style={styles.button}
          >
            <Text
              style={[
                {
                  color: colors.text,
                },
                styles.buttonText,
              ]}
            >
              Save
            </Text>
          </TouchableOpacity>
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginHorizontal: 12,
    padding: 8,
    borderWidth: 1,

    borderRadius: 6,
  },
  buttonText: {
    margin: 12,
  },
  button: {
    backgroundColor: "#7957DA",
    alignItems: "center",
    marginHorizontal: 12,
    marginTop: 12,
    borderRadius: 8,
  },
  countLabel: {
    margin: 12,
    fontSize: 11,
  },
  container: {
    flex: 1,
  },
});
