import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React from "react";
import {
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { AddIntegrationButton } from "../../components/AddIntegrationButton";
import { Content } from "../../components/Content";
import { IntegrationColor } from "../../components/IntegrationColor";
import { useIntegrations } from "../../hooks/useIntegrations";
import { RootStackParamList } from "../../routes";
import { titleCase } from "../../utils/titleCase";
import { IntegrationCard } from "./IntegrationCard";

export function Integrations({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "Integrations">) {
  const { data } = useIntegrations();

  return (
    <View style={{ flex: 1 }}>
      <Content>
        <FlatList
          data={data}
          style={{ marginTop: 12 }}
          renderItem={({ item: integration, index }) => {
            return (
              <IntegrationCard
                integration={integration}
                onPress={() =>
                  navigation.navigate("IntegrationDetail", {
                    id: integration._id,
                  })
                }
              />
            );
          }}
          keyExtractor={(item) => item._id}
        />
      </Content>
      <AddIntegrationButton navigation={navigation} />
    </View>
  );
}

const styles = StyleSheet.create({
  type: { marginLeft: 6, fontSize: 16, color: "white" },
  button: {
    backgroundColor: "#343245",
    padding: 12,
    marginVertical: 6,
    marginHorizontal: 12,
    borderRadius: 8,
    flexDirection: "row",
  },
});
