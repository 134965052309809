import { useTheme } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useState } from "react";
import {
  Alert,
  Linking,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { WorthApi } from "../../../api/WorthApi";
import { Content } from "../../../components/Content";
import { RootStackParamList } from "../../../routes";

export function AddBinance({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "AddBinance">) {
  const { colors } = useTheme();

  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");

  return (
    <SafeAreaView style={styles.container}>
      <Content>
        <Text
          style={{
            color: "white",
            fontSize: 20,
            fontWeight: "500",
            marginVertical: 24,
            marginLeft: 16,
          }}
        >
          Add Binance Account
        </Text>
        <Text
          style={[{ color: colors.text, marginLeft: 16, marginBottom: 12 }]}
        >
          Connect your Binance account by using Binance API. Please follow{" "}
          <Text
            style={{ textDecorationLine: "underline", fontWeight: "bold" }}
            onPress={() =>
              Linking.openURL(
                "https://www.binance.com/en/support/faq/360002502072"
              )
            }
          >
            this guide
          </Text>{" "}
          for API generation. For your account security, please allow only the
          "Enable Reading" permission.
        </Text>
        <Text style={[{ color: colors.text }, styles.label]}>API Key:</Text>
        <TextInput
          keyboardType="default"
          style={[
            {
              color: colors.text,
              borderColor: colors.border,
            },
            styles.input,
          ]}
          value={apiKey}
          onChangeText={setApiKey}
        />
        <Text style={[{ color: "white" }, styles.label]}>API Secret:</Text>
        <TextInput
          keyboardType="default"
          style={[
            {
              color: colors.text,
              borderColor: colors.border,
            },
            styles.input,
          ]}
          value={apiSecret}
          onChangeText={setApiSecret}
        />
        <TouchableOpacity
          onPress={() => {
            console.log(apiKey, apiSecret);
            if (apiKey && apiSecret) {
              WorthApi.addBinanceSource(apiKey, apiSecret)
                .then((res) => {
                  navigation.navigate("IntegrationDetail", { id: res._id });
                })
                .catch((err) => {
                  if (Platform.OS === "web") {
                    alert(err.message);
                  } else {
                    Alert.alert(err.message);
                  }
                });
            }
          }}
          style={styles.button}
        >
          <Text
            style={[
              {
                color: colors.text,
              },
              styles.buttonText,
            ]}
          >
            Connect Binance Account
          </Text>
        </TouchableOpacity>
      </Content>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  input: {
    marginHorizontal: 16,
    padding: 8,
    borderWidth: 1,

    borderRadius: 6,
  },
  buttonText: {
    margin: 12,
  },
  button: {
    backgroundColor: "#7957DA",
    alignItems: "center",
    marginHorizontal: 12,
    marginTop: 24,
    borderRadius: 8,
  },
  label: {
    margin: 16,
    fontSize: 14,
  },
  container: {
    flex: 1,
  },
});
