import { useQuery } from "react-query";
import { Quote } from "../api/StockApi";
import { WorthApi } from "../api/WorthApi";

export function useStockPrices(): {
  state: "ready" | "loading";
  total: {
    amount?: number;
    currency?: string;
  };
  stocks: (Quote & { count: number })[];
} {
  const prices = useQuery("stockPrices", () => WorthApi.getStockPrices(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  return { state: prices.isFetched ? "ready" : "loading", ...prices?.data };
}
