import React from "react";
import { StyleSheet, Text, TouchableOpacity } from "react-native";

export function AddIntegrationButton({ navigation }: { navigation: any }) {
  return (
    <TouchableOpacity
      onPress={() => navigation.navigate("Add")}
      style={styles.addButton}
    >
      <Text style={styles.plusText}>+</Text>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  plusText: {
    fontSize: 36,
    fontWeight: "800",
    color: "white",
  },
  addButton: {
    position: "absolute",
    right: 32,
    bottom: 32,
    width: 56,
    height: 56,
    borderRadius: 32,
    backgroundColor: "#7957DA",
    alignItems: "center",
    justifyContent: "center",
  },
});
