import * as React from "react";

import { useTheme } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import { TextInput } from "react-native-gesture-handler";
import { useQuery } from "react-query";

import { RootStackParamList } from "../../../routes";
import { WorthApi } from "../../../api/WorthApi";
import { Content } from "../../../components/Content";

export function SearchStock({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "SearchStock">) {
  const { colors } = useTheme();
  const [query, setQuery] = React.useState<string>();

  const { data, isFetched } = useQuery(
    ["searchStock", query],
    () => WorthApi.searchStock(query!),
    {
      enabled: (query?.length || 0) > 1,
    }
  );
  return (
    <View style={{ flex: 1 }}>
      <Content>
        <Text style={[{ color: colors.text, fontSize: 18, margin: 12 }]}>
          Search Stocks
        </Text>
        <TextInput
          keyboardType="default"
          autoCapitalize="characters"
          placeholder="Search stocks..."
          style={[
            {
              color: colors.text,
              borderColor: colors.border,
            },
            styles.input,
          ]}
          value={query}
          onChangeText={(text) => setQuery(text)}
        />
        {data &&
          data.map((s) => (
            <StockSearchResultCard
              onPress={() =>
                navigation.navigate("AddStock", { symbol: s.symbol })
              }
              key={s.shortname}
              title={s.shortname}
              symbol={s.symbol}
              exhange={s.exchDisp}
              quoteType={s.quoteType}
            />
          ))}
        {(query?.length || 0) > 0 && isFetched && !data?.length && (
          <Text style={{ color: "white" }}> No result found</Text>
        )}
      </Content>
    </View>
  );
}

export const StockSearchResultCard = ({
  title,
  symbol,
  exhange,
  quoteType,
  onPress,
}: {
  title: string;
  symbol: string;
  exhange: string;
  quoteType: string;
  onPress?: () => void;
}) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      style={{
        backgroundColor: "#343245",
        padding: 12,
        marginVertical: 6,
        marginHorizontal: 12,
        borderRadius: 8,
        flexDirection: "row",
      }}
    >
      <View style={styles.left}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.countAndPrice}>{symbol}</Text>
      </View>
      <View style={styles.right}>
        <Text
          style={{
            marginTop: 6,
            fontSize: 10,
            color: "#FFFFFF",
          }}
        >
          {quoteType}
        </Text>
        <Text
          style={{
            marginTop: 6,
            fontSize: 11,
            fontWeight: "600",
            color: "#FFFFFF",
          }}
        >
          {exhange}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  input: {
    marginHorizontal: 12,
    padding: 8,
    borderWidth: 1,
    marginTop: 12,
    marginBottom: 12,
    borderRadius: 6,
  },
  buttonText: {
    margin: 12,
  },
  button: {
    backgroundColor: "#7957DA",
    alignItems: "center",
    marginHorizontal: 12,
    marginTop: 12,
    borderRadius: 8,
  },
  countLabel: {
    margin: 12,
    fontSize: 11,
  },
  container: {
    flex: 1,
  },
  right: { alignItems: "flex-end" },
  countAndPrice: {
    color: "#FFFFFF",
    marginTop: 6,
    fontSize: 11,
    fontWeight: "600",
  },
  left: { flex: 1 },
  title: { color: "#FFFFFF", fontWeight: "600" },
});
