import React from "react";
import { useRecoilValue } from "recoil";
import { hideAmounts } from "../atoms";
import { numberWithCommas } from "../utils/numberWithCommas";

export function Amount(props: { amount: number; currency: string }) {
  const hidden = useRecoilValue(hideAmounts);
  let amount = numberWithCommas(props.amount?.toFixed(2) || "");
  if (hidden) {
    amount = "*****," + amount.substring(amount.length - 2);
  }
  return <>{amount + " " + props.currency}</>;
}
