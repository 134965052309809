import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

export const IntegrationCard = ({
  title,
  onPress,
}: {
  title: string;
  onPress?: () => void;
}) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.button}>
      <View style={styles.container}>
        <Text style={styles.title}>+ {title}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  title: { color: "#FFFFFF", fontWeight: "600" },
  container: { flex: 1 },
  button: {
    backgroundColor: "#343245",
    padding: 12,
    marginVertical: 6,
    marginHorizontal: 12,
    borderRadius: 8,
    flexDirection: "row",
  },
});
