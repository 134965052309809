import { QueryClient, QueryKey } from "react-query";

export const queryClient = new QueryClient();

export async function getQuery<T>(
  queryKey: QueryKey,
  queryFn: () => Promise<T>,
  noCache?: boolean
): Promise<T> {
  if (!noCache) {
    const cached = queryClient.getQueryData<T>(queryKey);
    if (cached) {
      return Promise.resolve(cached);
    }
  }
  try {
    const queryResult = await queryFn();
    queryClient.setQueryData(queryKey, queryResult);
    return Promise.resolve(queryResult);
  } catch (err) {
    console.log("getQuery error", err);
    return Promise.reject(err);
  }
}
