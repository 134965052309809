import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useLayoutEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";

import { RootStackParamList } from "./routes";
import { SummaryCard } from "./components/SummaryCard";
import LottieView from "lottie-react-native";
import { useSummary } from "./hooks/useSummary";
import { Content } from "./components/Content";
import { AddIntegrationButton } from "./components/AddIntegrationButton";

export function Summary({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "Summary">) {
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const { data: summary, isError, error, refetch, isLoading } = useSummary();

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity onPress={() => navigation.navigate("Integrations")}>
          <Text style={{ paddingRight: 4, color: "white" }}>Integrations</Text>
        </TouchableOpacity>
      ),
    });
  }, []);

  return (
    <View style={styles.container}>
      <Content>
        {isLoading && (
          <ActivityIndicator style={styles.loading} color="white" />
        )}
        {isError && (
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              marginTop: 24,
            }}
          >
            <LottieView
              style={{
                width: 150,
                height: 150,
              }}
              autoPlay
              source={require("./assets/emptyBox.json")}
              // OR find more Lottie files @ https://lottiefiles.com/featured
              // Just click the one you like, place that file in the 'assets' folder to the left, and replace the above 'require' statement
            />
            <Text style={styles.noIntegration}>{(error as any)?.message}</Text>
          </View>
        )}
        <FlatList
          data={summary?.items}
          refreshing={refreshing}
          onRefresh={() => {
            setRefreshing(true);
            refetch().then(() => {
              setRefreshing(false);
            });
          }}
          ListHeaderComponent={
            summary && (
              <SummaryCard
                title="TOTAL"
                amount={summary?.total?.amount}
                currency={summary?.total?.currency}
              />
            )
          }
          keyExtractor={(item, index) => `${item.type}-${index}`}
          renderItem={({ item: summaryItem, index }) => (
            <SummaryCard
              key={index}
              title={summaryItem.title}
              amount={summaryItem.total?.amount}
              currency={summaryItem.total?.currency}
              onPress={() => {
                if (summaryItem.type === "stock") {
                  navigation.navigate("Stock");
                } else if (summaryItem.type === "crypto") {
                  navigation.navigate("Crypto");
                } else if (summaryItem.type === "bank") {
                  navigation.navigate("BankDetail", {
                    accountId: "summary",
                  });
                }
              }}
            />
          )}
          ListFooterComponent={() => {
            if (!summary?.failedIntegrations?.length) {
              return null;
            }
            return (
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: "center",
                  padding: 12,
                  marginTop: 24,
                  borderColor: "yellow",
                  borderWidth: 1,
                }}
              >
                <Text style={{ color: "#FFFFFF", marginBottom: 12 }}>
                  ⚠️ There are issues with some of the integrations
                </Text>
                {summary.failedIntegrations.map((i) => (
                  <TouchableOpacity
                    key={i.id}
                    onPress={() =>
                      navigation.navigate("IntegrationDetail", {
                        id: i.id,
                      })
                    }
                  >
                    <Text style={{ color: "lightblue" }}>{i.name}</Text>
                  </TouchableOpacity>
                ))}
              </View>
            );
          }}
        />
      </Content>
      <AddIntegrationButton navigation={navigation} />
    </View>
  );
}

const styles = StyleSheet.create({
  noIntegration: {
    color: "white",
    marginTop: 12,
  },
  loading: { marginTop: 12 },
  container: {
    flex: 1,
  },
});
