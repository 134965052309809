import * as React from "react";

import { useTheme } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";

import { RootStackParamList } from "../../../routes";
import { TextInput } from "react-native-gesture-handler";
import { Content } from "../../../components/Content";

export function AddManuelCrypto({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "AddManuelCrypto">) {
  const { colors } = useTheme();
  const [count, setCount] = React.useState<string | undefined>();
  const [coin, setCoin] = React.useState<string>();

  return (
    <View style={{ flex: 1 }}>
      <Content>
        <Text style={[{ color: colors.text }, styles.countLabel]}>Coin:</Text>
        <TextInput
          keyboardType="default"
          style={[
            {
              color: colors.text,
              borderColor: colors.border,
            },
            styles.input,
          ]}
          value={coin}
          onChangeText={(text) => setCoin(text)}
        />
        <Text style={[{ color: colors.text }, styles.countLabel]}>Amount:</Text>
        <TextInput
          keyboardType="number-pad"
          style={[
            {
              color: colors.text,
              borderColor: colors.border,
            },
            styles.input,
          ]}
          value={count}
          onChangeText={(text) => setCount(text)}
        />
        <TouchableOpacity
          onPress={() => {
            if (!coin) {
              return;
            }
          }}
          style={styles.button}
        >
          <Text
            style={[
              {
                color: colors.text,
              },
              styles.buttonText,
            ]}
          >
            Save
          </Text>
        </TouchableOpacity>
      </Content>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginHorizontal: 12,
    padding: 8,
    borderWidth: 1,

    borderRadius: 6,
  },
  buttonText: {
    margin: 12,
  },
  button: {
    backgroundColor: "#7957DA",
    alignItems: "center",
    marginHorizontal: 12,
    marginTop: 12,
    borderRadius: 8,
  },
  countLabel: {
    margin: 12,
    fontSize: 11,
  },
  container: {
    flex: 1,
  },
});
