import { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as React from "react";
import { StyleSheet } from "react-native";
import { WebView } from "react-native-webview";
import { RootStackParamList } from "./routes";

export function NordigenApprovalWebView({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "WebView">) {
  return (
    <WebView
      style={styles.container}
      source={{ uri: route.params.url }}
      onLoad={(event) => {
        console.log("url", event.nativeEvent.url);
      }}
    />
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
