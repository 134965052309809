import * as React from "react";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../../routes";
import {
  ActivityIndicator,
  FlatList,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from "react-native";
import { CoinCard } from "./CoinCard";
import { SummaryCard } from "../../components/SummaryCard";
import { Content } from "../../components/Content";
import { useCryptoSummary } from "../../hooks/useCryptoSummary";
import { Amount } from "../../components/Amount";
import { IntegrationColor } from "../../components/IntegrationColor";

export function Crypto({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "Crypto">) {
  const { data: summary } = useCryptoSummary();

  return (
    <View style={styles.container}>
      <Content>
        {!summary && <ActivityIndicator style={styles.loading} color="white" />}

        <FlatList
          ListHeaderComponent={
            summary?.total && (
              <>
                <SummaryCard
                  title={"CRYPTO"}
                  amount={summary?.total.amount}
                  currency={summary?.total.currency}
                />
                <Text
                  style={{
                    color: "white",
                    fontSize: 20,
                    marginLeft: 16,
                    marginTop: 12,
                  }}
                >
                  Accounts
                </Text>
                <ScrollView
                  horizontal
                  contentContainerStyle={{ minHeight: 84, marginBottom: 24 }}
                  style={{ height: 84 }}
                  showsHorizontalScrollIndicator={false}
                >
                  {summary?.accounts.map((account, index) => {
                    const { amount, currency } = account;

                    return (
                      <View
                        key={index}
                        style={{
                          width: 150,
                          backgroundColor: "#343245",
                          padding: 12,
                          margin: 12,
                          borderRadius: 8,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Text style={{ color: "white" }}>
                            {account.name || "Account"}
                          </Text>
                          <IntegrationColor
                            integrationId={account.integration_id}
                          />
                        </View>

                        <Text style={{ color: "white" }}>
                          <Amount amount={amount} currency={currency} />
                        </Text>
                      </View>
                    );
                  })}
                </ScrollView>
                <Text
                  style={{
                    color: "white",
                    fontSize: 20,
                    marginLeft: 16,
                    marginVertical: 12,
                  }}
                >
                  Coins
                </Text>
              </>
            )
          }
          data={summary?.items}
          keyExtractor={(item) => item.symbol}
          renderItem={({ item }) => (
            <CoinCard
              title={item.symbol}
              count={item.amount}
              price={item.price}
              details={item.details}
              currency={"EUR"}
            />
          )}
        />
      </Content>
    </View>
  );
}

const styles = StyleSheet.create({
  loading: { marginTop: 12 },
  container: { flex: 1 },
});
