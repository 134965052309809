import * as React from "react";

import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { SummaryCard } from "../../components/SummaryCard";

import { RootStackParamList } from "../../routes";
import { useStockPrices } from "../../hooks/useStockPrices";
import { StockCard } from "./StockCard";
import { Content } from "../../components/Content";

export function Stock({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "Stock">) {
  const stockPrices = useStockPrices();
  return (
    <View style={{ flex: 1 }}>
      <Content>
        {stockPrices?.state !== "ready" && (
          <ActivityIndicator style={{ marginTop: 24 }} color={"white"} />
        )}
        {stockPrices?.state === "ready" && (
          <>
            <SummaryCard
              title="STOCK"
              onPress={() => navigation.navigate("Stock")}
              amount={stockPrices?.total?.amount || 0}
              currency={stockPrices?.total?.currency || "EUR"}
            />
            {stockPrices?.stocks.map((s) => (
              <StockCard
                key={s.symbol}
                /*    onPress={() =>
                navigation.navigate("StockDetail", { symbol: s.symbol })
              } */
                title={s.shortName}
                price={s.regularMarketPrice.raw}
                count={s.count}
                changePercentage={s.regularMarketChangePercent.raw}
                currency={s.currency}
              />
            ))}
          </>
        )}
      </Content>
      <TouchableOpacity
        onPress={() => navigation.navigate("SearchStock")}
        style={styles.addButton}
      >
        <Text style={styles.plusText}>+</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  plusText: { fontSize: 36, color: "white", fontWeight: "800" },
  addButton: {
    position: "absolute",
    right: 32,
    bottom: 32,
    width: 56,
    height: 56,
    borderRadius: 32,
    backgroundColor: "#7957DA",
    alignItems: "center",
    justifyContent: "center",
  },
});
