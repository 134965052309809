import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useEffect, useState } from "react";
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageSourcePropType,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Institution } from "../../../api/Nordigen";
import { WorthApi } from "../../../api/WorthApi";
import { Content } from "../../../components/Content";
import { RootStackParamList } from "../../../routes";

export function SelectInstitution({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "SelectInstitution">) {
  const [institutions, setInstitutions] = useState<Institution[]>();

  useEffect(() => {
    WorthApi.getInstitutions(route.params!.country).then((res) =>
      setInstitutions(res as Institution[])
    );
  }, []);

  return (
    <SafeAreaView style={styles.container}>
      <Content>
        <Text
          style={{
            color: "white",
            fontSize: 20,
            fontWeight: "500",
            marginVertical: 24,
            marginLeft: 16,
          }}
        >
          Select an institution
        </Text>

        <FlatList
          style={styles.flatList}
          data={institutions}
          keyExtractor={(i) => i.bic}
          ListEmptyComponent={() => (
            <View>
              <ActivityIndicator />
            </View>
          )}
          renderItem={({ item }) => (
            <TouchableOpacity
              style={styles.button}
              onPress={async () => {
                WorthApi.addBankSource(item.id, item.name).then((res) => {
                  if (res.link) {
                    if (Platform.OS === "web") {
                      (window as any).location = res.link;
                    } else {
                      navigation.navigate("WebView", {
                        url: res.link,
                      });
                    }
                  }
                });
              }}
            >
              <Image
                style={styles.logo}
                resizeMode="center"
                source={{ uri: item.logo }}
              />
              <Text style={styles.name}>{item.name}</Text>
            </TouchableOpacity>
          )}
        />
      </Content>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  name: { marginLeft: 8, color: "white" },
  logo: { width: 48, height: 48 },
  button: {
    flexDirection: "row",
    alignItems: "center",
    padding: 4,
    paddingLeft: 16,
  },
  flatList: { marginTop: 0 },
  container: {
    flex: 1,

    /*   alignItems: "center",
    justifyContent: "center", */
  },
});
