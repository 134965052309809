import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { TransactionType } from "../../api/WorthApi";
import { Amount } from "../../components/Amount";
import { IntegrationColor } from "../../components/IntegrationColor";
import { numberWithCommas } from "../../utils/numberWithCommas";

export const TransactionCard = ({
  title,
  price,
  currency,
  date,
  bank,
  type,
  onPress,
}: {
  title: string;
  price: number;
  currency: string;
  date: string;
  bank?: {
    name: string;
    integration_id: string;
  };
  type: TransactionType;
  onPress?: () => void;
}) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.button}>
      <View style={[styles.left, { opacity: type === "booked" ? 1 : 0.5 }]}>
        <Text style={styles.title}>{title}</Text>
        <View
          style={{ flexDirection: "row", alignItems: "center", marginTop: 4 }}
        >
          <Text style={styles.date}>{date}</Text>
          {bank && (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  marginLeft: 12,
                  marginRight: 4,
                  color: "white",
                }}
              >
                {bank.name}
              </Text>
              <IntegrationColor integrationId={bank.integration_id} />
            </View>
          )}
        </View>
      </View>
      <View style={styles.right}>
        <Text
          style={[
            styles.price,
            {
              color: price == 0 ? "#FFFFFF" : price > 0 ? "green" : "red",
              opacity: type === "booked" ? 1 : 0.3,
            },
          ]}
        >
          {price > 0 ? "+" : ""}
          <Amount amount={price} currency={currency} />
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  price: { fontSize: 14, fontWeight: "600" },
  date: { color: "#FFFFFF" },
  title: { color: "#FFFFFF", fontWeight: "600" },
  right: { alignItems: "flex-end" },
  left: { flex: 1 },
  button: {
    backgroundColor: "#343245",
    padding: 12,
    marginVertical: 6,
    marginHorizontal: 12,
    borderRadius: 8,
    flexDirection: "row",
  },
});
