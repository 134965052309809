import { useQuery } from "react-query";
import { WorthApi } from "../api/WorthApi";

export function useCryptoSummary() {
  return useQuery(CRYPTO_SUMMARY_KEY, () => WorthApi.getCrypto(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
}

export const CRYPTO_SUMMARY_KEY = "crypto";
