export type RootStackParamList = {
  Bootstrap: undefined;
  AddBank: undefined;
  SelectCountry: undefined;
  SelectInstitution: { country: string };
  BankDetail: { accountId: string };
  WebView: { url: string };
  Summary: undefined;
  Stock: undefined;
  StockDetail: { symbol: string };
  Crypto: undefined;
  Add: undefined;
  Integrations: undefined;
  IntegrationDetail: { id: string };
  AddStock: { symbol: string };
  SearchStock: undefined;
  AddManuelCrypto: undefined;
  Login: undefined;
  AddBinance: undefined;
  AddCryptoWallet: { type: "SOL" | "AVAX" };
};

export const linking = {
  prefixes: ["https://worth.cancit.com", "worth://"],
  config: {
    screens: {
      Bootstrap: "",
      AddBank: "bank/new",
      BankDetail: "bank/:accountId",
      WebView: "webview",
      Summary: "summary",
      Stock: "stock",
      Crypto: "crypto",
      StockDetail: "stock/:symbol",
      Integrations: "integrations",
      IntegrationDetail: "integrations/:id",
      AddStock: "stock/add/:symbol",
      SearchStock: "stock/add",
      Login: "login",
      Add: "add",
      SelectCountry: "add/bank/country",
      SelectInstitution: "add/bank/country/:country",
      AddBinance: "add/crypto/binance",
      AddCryptoWallet: "add/crypto/wallet",
    },
  },
};
